<template>
  <div>
    <b-modal
      id="add-direct-instock-one-merchandise"
      ref="modal"
      header-class="headerModal"
      centered
      no-close-on-backdrop
      :title="`${$t('AddToStock')}: ${articleNumberName}`"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          :label="$t('MinimalQuantity')"
        >
          <div style="display:flex;justify-content: space-between;align-items: center;gap:10px;">
            <b-form-input
              v-model.number="articleIdsNumberOne.minimalQuantity"
              type="number"
            />
          </div>
        </b-form-group>
      </b-form>
      <b-form-group :label="$t('Location')">
        <vue-select
          v-model="location"
          :options="getLocationsMoving"
          :reduce="(e) => e.locationId"
          :placeholder="$t('SelectLocation')"
          label="locationName"
          aria-describedby="input-1-live-feedback"
        />
      </b-form-group>
      <p
        v-if="buttonSubmited == true && location == null"
        style="color: red;font-weight:600"
      >
        {{ $t('SelectLocation') }}
      </p>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          style="margin-top: 15px; margin-right: 15px;"

          @click="onSubmit"
        >
          {{ $t('AddToStock') }}

        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-top: 15px; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>

    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';


export default {
  components: {
  },
  props: ['eSize', 'colorData', 'materials', 'articleIdsNumberOne', 'articleNumberName'],
  data() {
    return {
      quantity: 1,
      location: null,
      buttonSubmited: false,
    }
  },
  computed: {
    ...mapGetters([
      'getIsLoading', 'getLocationsMoving',
    ]),
  },
  watch: {
  },
  methods: {
    ...mapActions([
      'changeLoadingtoTrue', 'locations_by_type',
    ]),
    removeArticle(index) {
      this.articleIdsNumberOne.splice(index, 1);
    },
    onSubmit() {
      this.buttonSubmited = true
      if (this.location == null && this.buttonSubmited) {
        return;
      }
      const object = {
        locationId: this.location,
        ...this.articleIdsNumberOne,
      }
      this.$emit('AddDirectInStock', object);
      this.$emit('resetArticlesOne')
      this.$refs.modal.hide();
      this.resetForm();
    },
    onCancel() {
      this.$emit('resetArticlesOne')
      this.$refs.modal.hide();
      this.resetForm();
    },
    resetForm() {
      this.location = null;
      this.buttonSubmited = false
    },
  },
}
</script>

    <style lang="scss" scoped>
    .cv {
      display: flex;
      flex-direction: column;
    }

    .flex-form {
      display: flex;
      flex-direction: column;
    }
    </style>
