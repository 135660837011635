<template>
  <div
    :class="$route.path.includes('products') ? 'inside-product__wrapper2' : 'inside-product__wrapper' "
    style="
      flex-grow: 0.8;
      display: flex;
      flex-direction: column;
      align-items: flex;
      width:auto;
    "
    :style="$route.path.includes('products') ? 'margin-top: 46px' : getStyle()"
  >
    <!-- <div
      class="table__header"
      style="margin-bottom: 13px !important;margin-top: -12px;"
    >
      <button
        v-if="$route.path.includes('create-order') && getDressColors.length == 0"
        v-b-modal.add-custom
        class="button"
        style="border-radius:6px"
        @click="getMaterialVByMandP"
      >
        <b-icon-plus />
        Add Custom Dress
      </button>
    </div> -->
    <div style="width: 100%;  padding-top: 16px">
      <table
        class="team_table"
        :class="{'button--disabled': getMaterialsByArticleNum.length == 0 }"
      >
        <thead>
          <tr>
            <th>
              {{ $t('MaterialVariantName') }}
            </th>
            <th>
              {{ $t('Quantity') }}
            </th>
            <th>
              {{ $t('Length') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in getMaterialsByArticleNum.productSingleMaterialVariantDtos"
            :key="item.materialVariantId"
            style="cursor: pointer"
          >
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.quantity === 1 ? item.quantity+` ${$t('Part')}` : item.quantity > 0 ? item.quantity+` ${$t('Parts')}` : "" }}
            </td>
            <td>
              {{ item.length === 1 ? item.length+` ${$t('Meter')}` : item.length > 0 ? item.length+` ${$t('Meters')}` : "" }}
            </td>
          </tr>
          <tr v-if="getMaterialsByArticleNum.totalCost > 0">
            <td
              colspan="10"
              style="padding: 10px; background-color: #f4f5f6;"
            >
              {{ $t("TotalPriceForVariants") }}: {{ getMaterialsByArticleNum.totalCost }} €
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <CreateCustomOrderModal
      @createCustomOrder="createCustomOrder"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import CreateCustomOrderModal from '@/modals/CreateCustomOrderModal.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    // CreateCustomOrderModal,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    productMatObj: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    addedArticle: {
      type: Array,
    },
    createOrder: {
      type: Boolean,
    },
  },
  data() {
    return {
      dummy: '',
      searchTerm: '',
      rows: [],
    };
  },
  computed: {
    ...mapGetters(['getMaterialsByArticleNum', 'getDressColors']),
  },
  watch: {
    productMatObj() {
      this.addRows();
    },
  },
  methods: {
    ...mapActions(['addOrderInStore', 'getMaterialVariantsToMDCPagination']),
    async createCustomOrder(obj) {
      await this.addOrderInStore(obj);
    },
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    getStyle() {
      if (this.getDressColors.length != 0) {
        return 'margin-top: 48px'
      // eslint-disable-next-line no-else-return
      } else {
        return 'margin-top: 48px'
      }
    },
    addRows() {
      const x = this.productMatObj.productMaterialVariantDtos;
      const y = this.productMatObj.productMaterialQuantityDtos;

      this.rows = [...x, ...y];
    },

    // tdClass(row) {
    //   return row.vgtSelected ? 'row-clicked' : '';
    // },

    // onRowClick(p) {
    //   return p.selected ? 'selected' : '';
    // },
    async getMaterialVByMandP() {
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Meter',
      });
      await this.getMaterialVariantsToMDCPagination({
        pageNumber: 1,
        pageSize: 15,
        measurement: 'Part',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  width: 270px;
  height: auto;
  border-radius: 8px;
  border: 1px solid lightgray;
  background: white;
  position: absolute;
  top: calc(100% - 10px);
  left: 88%;
  transform: translateX(-50%);
  z-index: 1;
}

.actiontd {
  position: relative;
}
.minutes{
  display: flex;
  gap:4px;
}
.insideProductTable {
  width: 60%;
  padding-top: 16px
}
.team_table td {
  padding: 16px 10px !important;
}

.team_table th {
  padding: 10px;
}

.team_table th:first-child {
  border-radius: 0px 0px 0px 0px;
}

.team_table th:last-child {
  border-radius: 0px 0px 0px 0px;
}
.orange-color {
  color: orange;
}
.red-color {
  color: red;
}
.green-color {
  color: green;
}
.team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}
@media screen and (max-width: 1823px) {
  .inside-product__wrapper{
    margin-left: 20px;
  }
  .inside-product__wrapper2{
    width:100% !important;
  }
}
@media screen and (max-width: 1623px) {
  .inside-product__wrapper{
    margin-left: 20px;
  }
  .inside-product__wrapper2{
    width:100% !important;
  }
}
@media screen and (max-width: 1400px) {
.inside-product__wrapper{
  width: 200px !important;
  margin-left: 0px !important;
  // button{
  //   font-size: 11px;
  //   width: 100% !important;
  //   height: 36px;
  // }
}
.inside-product__wrapper2{
    width:100% !important;
  }
  .insideProductTable {
    width: 100%;
  }
  .popup {
    width: 270px;
    height: auto;
    border-radius: 8px;
    border: 1px solid lightgray;
    background: white;
    position: absolute;
    top: calc(100% + 0px); /* Adjust this value based on your design */
    left: 25%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

@media screen and (max-width: 1200px) {
  .inside-product__wrapper2 {
    margin-top: 30px !important;
    width: 100%;
  }
  .insideProductTable {
    width: 100%;
  }
}
@media screen and (max-width: 896px) {
  .inside-product__wrapper {
    width: auto !important;
    flex-basis: 50%;
  }
}
</style>
